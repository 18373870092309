.publicacion {
  padding: 10rem;
  background-attachment: fixed;
}

.container-publicacion {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.publicacion-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -15px;
}

.publicacion-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-publicacion {
  width: 50%;
}

.publicacion-text {
  margin-top: 2rem;
}

.publicacion-text h3 {
  font-size: 2rem;
  color: #f0f0f0;
  text-align: center;
  line-height: 1.5;
}

.publication-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.publication-link {
  width: 100%;
  display: flex;
  justify-content: center;
}
.publication-icon-box {
  border-radius: 1rem;
  cursor: pointer;
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.85);
  text-align: center;
  background-color: #33333383;
  color: white;
}

.publication-icon-box i {
  font-size: 32px;
  line-height: 1;
  color: rgb(0, 197, 142);
  margin-bottom: 10px;
  transition: transform 0.5s ease-in-out;
}

.publication-icon-box:hover {
  border-color: rgb(0, 197, 142);
  background-color: rgb(36, 30, 78);
  color: white;
}

.publication-icon-box:hover i {
  transform: translateY(-5px);
}

@media (max-width: 767px) {
  .publicacion {
    padding: 3rem;
  }

  .logo-publicacion {
    width: 80%;
  }

  .publicacion-text h3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .publication-btn {
    margin-top: 2rem;
  }

  .publication-icon-box {
    padding: 20px 15px;
  }

  .publication-icon-box i {
    font-size: 28px;
  }

  .publication-link {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
