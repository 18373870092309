.aviso-legal {
  margin: 2vw;
  position: absolute;
  z-index: 1100;
  border-radius: 20px;
  padding-bottom: 3rem;
  background-color: whitesmoke;
}

.aviso-legal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.aviso-legal-content {
  width: 100%;
}

.aviso-legal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.aviso-legal-header .aviso-legal-close-btn {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.aviso-legal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.aviso-legal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.aviso-legal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.aviso-legal-footer > * {
  margin: 0.25rem;
}

.aviso-legal-accept-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
}

.aviso-legal-accept-btn:hover {
  background-color: #5a6268;
}

.aviso-legal-title2,
h5,
p,
li {
  color: rgb(112, 110, 110);
}
