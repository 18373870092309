/* Dashboard Main */
.dashboardMainAdmin {
  position: absolute;
  margin: 2vw;
  min-width: 90vw;
  z-index: 1100;
  display: grid;
  grid-template-columns: 5% 95%;
  border-radius: 20px;
  padding-bottom: 3rem;
}

/* Estilos para la sección de datos de usuario */
.datos-user {
  margin: 0 auto;
  max-width: 60%;
  background-color: #aab3c5;
  padding: 1rem;
  border-radius: 1rem;
  overflow-x: auto;
}

.datos-user .btn-descarga {
  color: rgb(32, 31, 31);
  background-color: rgb(0, 197, 142);
  width: 30%;
  justify-content: center;
}

.datos-user h2 {
  font-size: xx-large;
  font-weight: 500;
}

/* Estilos para la tabla */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 2rem;
  white-space: nowrap;
}

.user-details p {
  color: black;
}

/* Estilos para las celdas de la tabla */
.table th,
.table td {
  color: black;
  padding: 8px;
  text-align: left;
}

/* Estilos para la fila de botones de paginación */
.button-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Estilos para los botones de página */
.button-page button {
  background-color: #cccccc;
  color: #4c4b4b;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  outline: none;
}

/* Estilos para el número de página */
.button-page span {
  margin: 0 5px;
}

/* Estilos para el botón activo */
.button-page button.active {
  background-color: rgb(0, 197, 142);
  color: white;
}

/* Estilos para las flechas */
.button-page button.arrow {
  color: #666666;
}

.button-page button.arrow:hover {
  color: #1d1d1d;
}

/* Estilos para las flechas cuando están deshabilitadas */
.button-page button.arrow[disabled] {
  color: #cccccc; /* Color gris para las flechas deshabilitadas */
}

/* Estilos para las diferentes clases de estado */
.datos-user .table .status-activo,
.datos-user .table .status-inactivo,
.datos-user .table .status-banneado,
.datos-user .table .status-suspendido {
  text-align: center;
}

.datos-user .table .status-activo {
  background-color: #33ad33;
}

.datos-user .table .status-inactivo {
  background-color: #ffff99;
}

.datos-user .table .status-banneado {
  background-color: #ff6666;
}

.datos-user .table .status-suspendido {
  background-color: #66b3ff;
}

/* Datos Empresa */

.datos-business {
  margin: 0 auto;
  max-width: 60%;
  background-color: #aab3c5;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
  overflow-x: auto;
}

.datos-business h2 {
  font-size: xx-large;
  font-weight: 500;
}

.datos-business .btn-descarga {
  color: rgb(32, 31, 31);
  background-color: rgb(0, 197, 142);
  width: 30%;
  justify-content: center;
}

/* Desplegable Business*/

.business-details button {
  margin-top: 1rem;
  margin-right: 5px;
  margin-bottom: 5px;
  width: auto;
  padding: 5px 10px;
  font-size: 14px;
}

/* Estilos para los botones de color */
.business-details button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.business-details button.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.business-details button.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.business-details button.btn-warning:hover {
  background-color: #e0a800;
  border-color: #e0a800;
}

.business-details button.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}

.business-details button.btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}

.business-details button.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.business-details button.btn-danger:hover {
  background-color: #c82333;
  border-color: #c82333;
}

@media only screen and (max-width: 768px) {
  .dashboardMainAdmin {
    grid-template-columns: 100%;
    margin: 0;
    min-width: auto;
    position: relative;
  }

  .datos-user {
    max-width: 100%;
  }

  .datos-business {
    max-width: 100%;
  }

  .button-page button {
    width: 25px;
    height: 25px;
  }

  /* Estilos para los detalles de la empresa */
  .business-details button {
    font-size: 10px;
    padding: 2px 6px;
  }
}

@media (max-width: 992px) {
  .datos-user {
    max-width: 100%;
  }

  .datos-business {
    max-width: 100%;
  }
}
