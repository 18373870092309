.contact-section {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 3rem;

  .contact-info {
    flex: 1;
    margin-right: 2rem;

    .contact-map {
      width: 100%;
      height: 250px;
      margin-bottom: 2rem;
      border: 0;
    }

    .contact-details {
      .contact-item {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        .contact-icon {
          font-size: 24px;
          margin-right: 1rem;
          color: rgb(0, 197, 142);
        }

        h4 {
          font-size: 18px;
          margin: 0;
          color: #333;
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }

  .contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form {
      display: flex;
      flex-direction: column;

      .form-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        input {
          width: 48%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        // Agrega margen derecho al input "Nombre"
        input:first-child {
          margin-right: 1rem;
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        width: 100%;
        padding: 0.5rem;
        margin-bottom: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
      }

      textarea {
        resize: none;
      }

      button {
        align-self: flex-start;
        padding: 0.75rem 1.5rem;
        border: 1px solid #000;
        background-color: rgb(0, 197, 142);
        color: #000000;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          background-color: rgb(36, 30, 78);
          color: white;
        }
      }
    }
  }
}

// Responsivo para pantallas pequeñas
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;

    .contact-info {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .contact-form {
      form {
        .form-row {
          flex-direction: column;

          input {
            width: 100%;
            margin-bottom: 1rem;
            margin-right: 0; // Restablece el margen derecho en pantallas pequeñas
          }
        }
      }
    }
  }
}
