/* --- General --- */
.dashboardMain,
.dashMain {
  position: absolute;
  margin: 2vw;
  min-width: 90vw;
  z-index: 1100;
  display: grid;
  grid-template-columns: 5% 95%;
  border-radius: 20px;
  padding-bottom: 3rem;
}

.background-item {
  background-color: whitesmoke;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

/* --- Header --- */
.titulo-dash {
  font-size: 3rem;
  text-align: center;
  color: grey;
  margin-top: 3rem;
  padding-bottom: 2rem;
}

/* --- Contenido principal (Content) --- */
.content-dash {
  display: grid;
  grid-template-columns: 75% 25%;
  height: 100%;
  background: #dbdbdb;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.left-content {
  grid-row: 1;
  grid-column: 1;
  background: #f6f7fb;
  margin: 15px;
  padding: 20px;
  border-radius: 15px;
}

.right-content {
  grid-column: 2;
  background: #f6f7fb;
  margin: 15px;
  padding-top: 2rem;
  padding: 1rem;
  border-radius: 15px;
}

/* --- Mobile-first (versión móvil para cambiar el orden de columnas) --- */
@media (max-width: 768px) {
  .content-dash {
    display: grid;
    grid-template-columns: 1fr; /* Una columna para móviles */
    grid-template-rows: auto auto; /* Dos filas */
  }

  /* Mostramos primero la columna derecha (right-content) */
  .right-content {
    grid-row: 1; /* Se muestra primero */
  }

  /* Luego la columna izquierda (left-content) */
  .left-content {
    grid-row: 2; /* Se muestra después */
  }

  .left-content,
  .right-content {
    margin: 0;
    padding: 5px;
  }
}

/* --- Consultados --- */
.consultados-container {
  margin-bottom: 40px;
}

.consultados-header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.consultados-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.mas-consultados h1 {
  margin: 0 0 20px;
  font-size: 1.4rem;
  font-weight: 700;
}

/* --- Imagenes dentro de Consultados --- */
.image-container {
  position: relative;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px;
  border-radius: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay h3 {
  margin-bottom: 8px;
  margin-right: 10px;
  color: #fff;
  font-size: 25px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  transform: translateY(20px);
  transition: transform 0.3s ease, font-weight 0.3s ease;
}

.image-container:hover .overlay {
  cursor: pointer;
  background: linear-gradient(180deg, transparent, transparent, white);
  opacity: 1;
}

.image-container:hover .overlay h3 {
  font-weight: 600;
  color: rgb(0, 197, 142);
  transform: translateY(0);
}

/* --- Descuentos --- */
.descuentos-container {
  margin-top: 40px;
}

.descuentos-header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.descuentos-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.descuento-item {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.day {
  text-align: center;
}

.day h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.day p {
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
}

.activity {
  flex: 1;
  padding-left: 20px;
}

.activity h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.activity p {
  font-size: 1rem;
  color: #777;
}

.btn-descuentos {
  background-color: rgb(0, 197, 142);
  border: none;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
}

.btn-descuentos:hover {
  background-color: rgb(0, 170, 122);
}

/* --- Notificaciones y Comentarios --- */
.notificaciones-container {
  margin-top: 40px;
}

.notificaciones-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.notificaciones-container .badge {
  background-color: rgb(0, 197, 142);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.9rem;
  margin-left: 10px;
}

.comentarios-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comentario-item {
  background-color: #f8f8f8;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comentario-item .text h5 {
  font-size: 1.2rem;
  font-weight: bold;
}

.comentario-item .text h4 {
  font-size: 1rem;
  color: rgb(0, 197, 142);
  margin-top: 5px;
}

.comentario-item .text p {
  font-size: 0.9rem;
  color: #777;
}

.more-comments {
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  color: rgb(0, 197, 142);
}

/* --- Recomendaciones --- */
.recomendaciones {
  display: flex;
  flex-direction: column;
}

.recomendaciones h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Card de negocio recomendado */
.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  max-width: 300px;
  height: 100%;
}

.card:hover {
  transform: scale(1.05);
}

.card-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.card-user-info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.card-user-info h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.card p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

/* --- Mobile Styles --- */
@media screen and (max-width: 992px) {
  .dashboardMain {
    grid-template-columns: 100%;
    margin: 0;
    min-width: auto;
    position: relative;
  }
  .content-fav {
    grid-template-columns: 1fr;
    margin: 0;
    padding: 15px;
  }
  main {
    grid-template-columns: 100%;
    margin: 20px;
  }
  .content-dash {
    display: block;
    grid-template-columns: 100%;
  }

  .contenido-consultados {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 150px);
  }
}

@media (max-width: 700px) {
  .prox-desc {
    max-width: 100%;
  }

  .favoritos {
    width: 100%;
    padding: 0;
  }

  main {
    grid-template-columns: 15% 85%;
  }

  .mas-consultados,
  .descuentos {
    margin-top: 10px;
  }
}

@media (max-width: 572px) {
  .prox-desc {
    grid-template-columns: auto;
  }
}

/* --- Otros elementos personalizados --- */
.dashboardClose {
  position: absolute;
  background-color: rgb(0, 197, 142);
  top: 10px;
  color: white;
  font-weight: 600;
  padding: 1rem;
  right: 10px;
  border-radius: 50%;
  width: fit-content;
  border: 1px solid black;
}

.personal-bests-container {
  grid-template-rows: repeat(3, 98px);
  gap: 15px;
}

/* --- Right Content --- */
.right-content {
  grid-column: 2;
  background: #f6f7fb;
  margin: 15px;
  padding: 20px;
  border-radius: 15px;
}

.container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

/* --- User Info Section --- */
.user-info {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.avatar-section {
  margin-top: 20px;
}

.avatar-section img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

/* --- Recomendaciones Section --- */
.recomendaciones-section {
  margin-top: 40px;
}

.recomendaciones-section h1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* --- Custom Column (for replacing Bootstrap 'col') --- */
.custom-column {
  flex: 1 1 100%; /* Ajuste basado en el comportamiento de columnas */
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* Centrar la tarjeta */
}

/* --- Business Card (tarjetas con el mismo tamaño de alto) --- */
.business-card {
  width: 100%; 
  max-width: 300px; 
  height: 400px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative; /* Necesario para posicionar el overlay */
  transition: transform 0.3s ease;
}

.business-card:hover {
  transform: scale(1.05);
}

/* --- Custom Image --- */
.custom-image {
  width: 100%;
  height: 100%; 
  object-fit: cover;
  border-radius: 10px 10px 0 0; /* Solo las esquinas superiores redondeadas */
}

/* --- Custom Overlay and Text --- */
.custom-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro translúcido */
  color: #fff; /* Texto blanco */
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease, padding 0.3s ease; /* Transición suave */
}

.business-card:hover .custom-overlay {
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 20px; 
  font-size: 1.3rem; 
}

/* --- Extra para ajustar el contenedor --- */
.custom-text {
  transition: font-size 0.3s ease;
}
