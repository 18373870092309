.businessCard-container {
  margin-bottom: 20px;
  padding: 1rem;
}

.businessCard {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #333333b6;
  cursor: default;
  color: #fff;

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.businessCard:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.businessCard figure {
  position: relative;
  overflow: hidden;
}

.businessCard img {
  width: 100%;
  height: 210px;
  display: block;
}

.businessCard-content {
  text-align: center;
  padding: 20px;
}

.businessCard-content h3 {
  margin-bottom: 15px;
  color: #7a7a7a;
}

.businessCard-content h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: whitesmoke;
  font-weight: 500;
}

.businessCard-content h5 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #e3e3e3;
  font-weight: 800;
  text-align: center;
}

.businessCard-content p {
  line-height: 1.8;
  margin: 0;
  margin-bottom: 5px;
  color: #f5f5f5;
}

.businessCard-content a {
  display: inline-block;
  padding: 10px;
  margin-top: 10px;
  text-decoration: none;
  color: rgb(0, 197, 142);
  border: 1.5px solid white;
  border-radius: 4px;
  transition: all 400ms ease;
  margin-bottom: 5px;
}

.businessCard-content a:hover {
  color: #fff;
  text-decoration: underline;
  background-color: #241e4e;
}

/*==== INICIO FAVORITOS ======*/

.businessCard-favorite {
  top: 10px;
  right: 10px;
}

.businessCard-favoriteLabel {
  cursor: pointer;
}

.businessCard-starIcon {
  font-size: 3rem;
  background: #241e4e9a;
  border: 1px solid black;
  padding: 1px 5px;
  border-radius: 50%;
}

.businessCard-favorite > input {
  display: none;
}

.businessCard-favorite > label {
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin-top: auto;
  color: #e3e3e3;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
}

.businessCard-favorite > label:hover {
  color: gold;
}

.businessCard-favorite > input:checked ~ label,
.businessCard-favorite > input:checked ~ label:hover,
.businessCard-favorite > input:checked ~ label ~ label {
  color: gold;
}

/*=== FIN STAR ===*/

/* Estilos para dispositivos móviles */
@media (max-width: 1420px) {
  .businessCard-container {
    margin-bottom: 0;
    padding: 0;
  }

  .businessCard-content {
    padding: 10px 0px 0px 0px;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .businessCard {
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .businessCard-container {
    padding: 0.5rem;
  }
  .businessCard-content {
    padding: 10px;
  }
  .businessCard-content h5 {
    font-size: 1rem;
  }
}
