.contSpinners {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9000;
    background-color: rgba(0, 0, 0, 0.5);
    filter: brightness(300%) saturate(50%);
}

.imageSpinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
}

.bgSpinner {
    background-color: rgb(35, 35, 35);
}