#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 20px 20px;
  padding-top: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  width: 100%;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(0, 0, 0, 0.8);
}

.row-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.row-header > * {
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 992px) {
  .row-header {
    display: block;
    max-width: 100%;
  }
}

.logo-container {
  flex: 0 0 auto;
  max-width: 25%;
  margin-left: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  display: block;

  @media (max-width: 992px) {
    flex: 100%;
    max-width: 100%;
    align-items: center;
    margin-left: 0;
    text-align: center;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    display: block;
  }
}

.logo-nav {
  max-width: 100%;
  height: auto;

  @media (max-width: 576px) {
    max-width: 150%;
  }

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }
}

#header img {
  max-height: 50px;
}

.btnNavs {
  width: auto;
  padding-top: 15px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 1200px) {
    padding: 0.5rem;
    margin: 0;
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .btnNavs {
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
  }
  .ingresa {
    width: 100%;
    padding: 1rem;
    font-size: 18px;
  }
}

.btn-publica,
.btnCategorias {
  width: auto;
  font-size: 13px;
  text-align: center;
  display: inline-block;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: whitesmoke;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  transition: all 0.4s ease-in-out;
  background: linear-gradient(135deg, rgb(0, 197, 142), rgb(62, 51, 132));
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border: none;
}

.btn-publica:hover,
.btnCategorias:hover {
  background-color: rgb(0, 197, 142);
  color: rgba(234, 234, 0, 0.886);
  border-color: rgb(0, 197, 142);
}

.ingresa {
  background-color: rgb(173, 255, 232);
  color: #241e4e;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.ingresa:hover {
  background-color: rgb(0, 197, 142);
  color: #fff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);
  transform: translateY(-2px);
}

@media (max-width: 576px) {
  .btn-publica,
  .btnCategorias,
  .ingresa {
    width: 100%;
    padding: 1rem;
    font-size: 18px;
    margin-bottom: 1rem;
  }
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  background-color: rgb(0, 197, 142);
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.vefrek-logo {
  width: 40%;
}

@media (min-width: 992px) {
  .btnNavs {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
  }

  .btnNavs:not(:first-child) {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  #header {
    background-color: black;
    position: relative;
  }
}
