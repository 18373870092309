.negocio-card-col {
  width: 100%;
  padding: 0.5rem;

  @media (min-width: 768px) {
    width: calc(100% / 3);
  }

  @media (min-width: 1200px) {
    width: calc(100% / 4);
  }
}

.negocios-recomendados {
  margin-top: 3rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding-top: 2rem;
  background-color: #f8f9fa;
  
.negocios-recomendados > div {
  display: flex;
  flex-direction: column; 
}

.filterTypeContRecomendados {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.filter-options {
  display: flex;
  justify-content: flex-start;
  gap: 2rem; 
  margin-top: 0.5rem; 
}

.filterTypeInputRecomendados {
  margin-right: 0.5rem; 
}

h5 {
  margin-bottom: 0.5rem;
}


  h5 {
    color: #333;
    font-size: 1.5rem;
    padding: 0.5rem;
    text-align: start;
  }

  h1 {
    color: #000;
    font-size: 2.5rem;
    margin-top: 10px;
    text-align: start;
    padding-bottom: 2rem;
  }
}

.filter-recomendados {
  flex: 1 0 0%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filterTypeContRecomendados {
  display: flex;
  margin-left: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  p {
    padding-left: 0.5rem;
  }
}

.filterTypeInputRecomendados {
  position: relative;
  width: 2rem;
  height: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgb(0, 197, 142);
  border-radius: 5rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.filterTypeLabelRecomendados {
  margin-left: 0.5rem;
  padding-right: 2rem;
  font-size: 1rem;
  color: rgb(0, 197, 142);
}

.filterTypeInputRecomendados {
  position: relative;
  width: 2rem;
  height: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgb(0, 197, 142);
  border-radius: 50%; /* Hace que el checkbox sea un círculo */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.filterTypeInputRecomendados::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.filterTypeInputRecomendados:checked::before {
  background-color: #00c58d;
  opacity: 1;
}

.row-recomendados {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;

  & > * {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.filter-btn-recomendados {
  background-color: #e2e8f0;
  color: #4a5568;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-btn-recomendados:hover {
  background-color: #cbd5e0;
}

.filter-btn-recomendados-active {
  background-color: rgb(0, 197, 142);
  color: black;
  border-color: rgb(0, 197, 142);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.filter-btn-recomendados-active:hover {
  background-color: rgb(220, 220, 6);
  color: black;
}

.row-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-right: -15px;
  margin-left: -15px;
}

.row-cards > * {
  flex: 0 1 calc(25% - 30px);
  margin-bottom: 2rem;
}

@media (max-width: 992px) {
  .row-cards > * {
    flex: 0 1 calc(33.333% - 20px);
  }
}

@media (max-width: 768px) {
  .row-cards > * {
    flex: 0 1 calc(50% - 15px);
  }
}

@media (max-width: 576px) {
  .row-cards > * {
    flex: 0 1 calc(100% - 10px);
  }
}

.filter-img-negocios {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card-portfolio {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .card-portfolio {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 1200px) {
  .card-portfolio {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 576px) {
  .filterTypeContRecomendados {
    flex-direction: column; 
    align-items: flex-start;
  }

  .filterTypeContRecomendados p {
    margin-top: 0.5rem; 
    font-size: 1.2rem; 
  }
}

.emergencia-form p {
  color: white;
}