/* Datos empresa */

.form-label-business {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0.5rem;
}

.form-select-business {
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: none;
  background-color: #fff;
  color: #111827;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: auto;
  min-width: 150px;
  text-align: center;
}

@media (max-width: 768px) {
  .datos-form {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .campo-formEmpresa {
    width: 100%;
    margin-bottom: 1rem;
  }

  .form-select-business {
    width: 100%;
    font-size: 0.875rem;
  }

  .form-label-business {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
}
