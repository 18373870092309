/* --- General --- */
.dashboardMainPerfil {
 grid-template-columns: 5% 95%;
  position: absolute;
  margin: 2vw;
  min-width: 90vw;
  z-index: 1100;
  display: grid;
  border-radius: 20px;
  background-color: grey;
}

@media screen and (max-width: 992px) {
  .dashboardMainPerfil {
    grid-template-columns: 100%;
    margin: 0;
    min-width: auto;
    position: relative;
  }
}

/* Botón Salir */
.dashboardCloseBtn {
  background-color: #ff4d4f;
  color: white;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.dashboardCloseBtn:hover {
  background-color: #ff7875;
}

/* Formulario */
.profileForm {
  background-color: white;
  border-radius: 0px 10px 10px 0px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.formSection {
  margin-bottom: 20px;
}

.sectionHeader {
  margin-bottom: 10px;
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.sectionDescription {
  font-size: 0.9rem;
  color: #666;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formLabel {
  font-weight: bold;
  margin-bottom: 5px;
}

.formInput, .formSelect {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.formInput:focus, .formSelect:focus {
  border-color: #40a9ff;
  outline: none;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.perfilAvatar {
  display: flex;
  align-items: center;
  gap: 15px;
}

.perfil_avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.userIcon {
  width: 80px;
  height: 80px;
  color: gray;
}

.changeAvatarBtn {
  background-color: #0e551c !important;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.changeAvatarBtn:hover {
  background-color: #0e551c;
}

.fileInput {
  display: none;
}

/* Botones de acción */
.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.saveBtn, .cancelBtn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
}

.saveBtn {
  background-color: #28a745 !important;
  color: white;
}

.saveBtn:hover {
  background-color: #218838 !important;
}

.cancelBtn {
  background-color: #dc3545 !important;
  color: white;
}

.cancelBtn:hover {
  background-color: #c82333 !important;
}

/* Grid de la información personal */
.formGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Campos obligatorios */
.required {
  color: red;
  font-weight: bold;
}
