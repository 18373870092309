/* Sección URL */
.url-section {
  margin-bottom: 2rem;

  label {
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0.5rem;
    display: block;
  }

  .url-vfk {
    display: flex;
    align-items: center;
    background-color: #2e3b4e;
    padding: 0.5rem;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .url-vfk span {
    color: #ffd700;
    font-weight: bold;
    margin-right: 0.5rem;
  }

  .url-vfk input {
    flex-grow: 1;
    padding: 0.5rem;
    border: none;
    border-radius: 0.375rem;
    background-color: #ffffff;
    color: #111827;
    font-size: 1rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .url-vfk input::placeholder {
    color: #6b7280;
    font-style: italic;
  }

  .url-vfk input:focus {
    background-color: #e2e8f0;
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
}

/* Sección Descripción */
.description-section {
  margin-bottom: 2rem;

  label {
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 0.5rem;
    display: block;
  }

  .txt-form {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid #ccc;
    background-color: #2e3b4e;
    color: #ffffff;
    font-size: 1rem;
    resize: vertical;
    min-height: 120px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .txt-form::placeholder {
    color: #6b7280;
    font-style: italic;
  }

  .txt-form:focus {
    background-color: #3b4a61;
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
}

/* Sección de Imágenes */
.carga-logo-section,
.images-section {
  margin-bottom: 2rem;
}

.carga-seclogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.carga-logo-preview {
  height: 150px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: contain;
  background-color: white;
}

.carga-btn-custom {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #e27c2d !important;
  color: #ffffff !important;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease !important;
  margin-bottom: 1rem;
}

.carga-btn-custom:hover {
  background-color: #c56a23 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.carga-company-logo-input {
  display: none;
}

.carga-cuadro-edicion {
  position: relative;
  border: 2px dashed #ccc;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carga-cuadro-edicion p {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

.carga-icon-user {
  font-size: 4rem !important; 
  height: 4rem !important; 
  width: 4rem !important;
  color: #6b7280; 
  margin-bottom: 0.5rem; 
}

.carga-file-upload-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.carga-images-preview-cont {
  width: 100%;
  gap: 20px;
}

.carga-image-cont {
  position: relative;
  height: 150px;
  width: 150px;
}

.carga-image-deleteIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  cursor: pointer;
}

.carga-image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: white;
}

.carga-images-preview-cont {
  width: 100%;
  gap: 20px;
}

.carga-file-upload-wrapper input[type="file"] {
  display: none;
}

.carga-file-upload-wrapper-label {
  background-color: #007bff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}


/*Btns Carga Empresa*/

.btn-cancelar-carga {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  margin: 1rem;
  font-weight: bold;
  border-radius: 0.375rem;
  cursor: pointer;
  background-color: #e74c3c !important;
  color: #ffffff !important; 
  border: none !important;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-cancelar-carga:hover {
  background-color: #c0392b !important; 
}

.btn-cancelar-carga:active {
  transform: scale(0.98) !important; 
}
