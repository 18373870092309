/* PopUp */
.popCarga1 {
  position: absolute;
  margin: 2vw;
  min-width: 90vw;
  z-index: 1200;
  display: grid;
  grid-template-columns: 5% 95%;
  border-radius: 20px;
  padding-bottom: 3rem;
}

.popCarga {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e03f3f;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1;
  max-width: 80%;
  max-width: 400px;
  overflow-y: auto;
  margin-top: 4rem;
}

.popCarga p {
  text-align: start;
  margin-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 100;
}

/* Estilos para los botones de carga y cancelar */
.btn-reclamar {
  margin-top: 1rem;
  background-color: red;
  color: white;
  font-weight: 600;
  border: 2px solid #000;
  width: 100%;
  text-align: center;
}

.btn-container .btn-reclamar:hover {
  background-color: #4e2b1e;
  color: white;
}

.popCarga .btn-cancelar {
  background-color: rgb(115, 111, 111);
  margin-top: 1rem;
  font-weight: 600;
  color: #000;
  border: 2px solid #000;
  width: 100%;
  text-align: center;
}

.popCarga .btn-cancelar:hover {
  background-color: black;
  color: white;
}
