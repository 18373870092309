.search-container {
  min-width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .search-container {
    width: 100%;
    justify-content: center;
  }
}

.search-container .searchBar-container {
  width: 100%;
}

.searchBar-container {
  position: relative;
}

.searchBar-resultsContainer {
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  margin: auto;
  background-color: white;
  width: max-content;
  max-width: calc(100vw - 14px - 2rem);
  align-items: flex-start;
  opacity: 0;
  z-index: 500;
}

.searchBar-searchResult {
  padding: 0.5rem 1rem;
  padding-left: 0.5rem;
  text-align: left;
  color: rgb(75, 75, 75);
  border-bottom: 1px solid rgb(223, 223, 223);
  width: 100%;
}

.searchBar-searchResult:hover {
  color: black;
  background-color: rgba(126, 243, 210, 0.521);
}

.searchBar-searchInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.searchBar-searchInput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

@media only screen and (orientation: portrait) {
  .searchBar-resultsContainer {
    max-width: calc(100vw - 1rem);
  }
}
