/* Horarios */
.horarios-cont {
  display: table;
  margin: 0 auto;
}

.horarios-cont td {
  padding: 5px;
}

.horarios-cont td:first-child {
  font-weight: bold;
  color: #fff;
  text-align: left;
  padding-left: 1rem;
}

.horarios-cont .btn-personalizar {
  width: auto !important;
  padding: 0.5rem 1rem !important;
  font-size: 0.875rem !important;
  margin: 0 0 1rem 1rem !important;
  border-radius: 0.375rem !important;
  border: 1px solid #ccc !important;
  background-color: #ffffff !important;
  color: #111827 !important;
  cursor: pointer !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
}

.btn-personalizar:focus {
  background-color: #e2e8f0;
  border-color: #007bff;
  outline: none;
}

.horarios-cont select {
  width: 80%;
  padding: 8px;
  border-radius: 1rem;
  border: 1px solid #ccc;
  background-color: #ffffff;
  color: #111827;
  font-size: 14px;
}

.horarios-cont .input-group select {
  margin-top: 0.5rem;
  border-radius: 1rem !important;
}

.horarios-cont select:focus {
  background-color: #e2e8f0;
  border-color: #007bff;
  outline: none;
}

.diaOpenClose {
  text-align: center;
}

.clear-btn {
  background-color: transparent;
  border: none;
  padding: 0.3rem;
  color: #b1ae08;
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
}

.clear-btn:hover {
  color: #e0f900;
}

/* Adaptación para dispositivos móviles */
@media (max-width: 768px) {
  .horarios-cont tr {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .horarios-cont .input-group select {
    margin-top: 0rem;
  }

  .horarios-cont td:first-child {
    width: 25%;
    text-align: left;
    font-weight: bold;
    padding: 10px 0;
    color: #fff;
  }

  .horarios-cont td:nth-child(2),
  .horarios-cont td:nth-child(3) {
    width: 35%;
    text-align: center;
  }

  .horarios-cont tr:nth-of-type(2n) {
    border-bottom: 1px solid #aaaaaabc;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .horarios-cont select {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .horarios-cont tr:nth-of-type(2n) {
    border-bottom: 1px solid #aaaaaabc;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
}


/* CargaEmpresa.scss */

.horarios-carga-empresa {
  display: flex;
  justify-content: center;


  table {
    width: 100%; 
    max-width: 600px; 
    text-align: center;
    border-spacing: 0 1rem;

    th, td {
      text-align: center;
    }

    th {
      color: #fff;
      font-weight: bold;
    }

    .diaOpenClose {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .input-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    select {
      width: 80%;
      padding: 8px;
      border-radius: 1rem;
      border: 1px solid #ccc;
      background-color: #ffffff;
      color: #111827;
      font-size: 14px;
      text-align: center;
    }

    .clear-btn {
      background-color: transparent;
      border: none;
      padding: 0.3rem;
      color: #b1ae08;
      cursor: pointer;
      font-size: 12px;
      margin-left: 5px;
    }

    .clear-btn:hover {
      color: #e0f900;
    }
  }
}
