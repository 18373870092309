/* Estilos para la columna A */
.columnaA {
  display: flex;
  flex-direction: column;
  width: 70%;  
}

.fila-logo-carrusel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo de la empresa */
.logo-empresa {
  width: 150px;
  height: 150px;
}

.logo-empresa-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #ddd;
}

/* Nombre y slogan de la empresa */
.empresa-nombre-slogan {
  position: relative;
  text-align: start;
}

.empresa-nombre {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.empresa-slogan {
  font-size: 16px;
  color: #777;
  margin-top: 5px;
}

/* Carrusel de imágenes */
.carrusel-imagenes {
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

  .slick-slider {
    position: relative;
  }

  /* Estilos para las flechas del carrusel */
  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    z-index: 2;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgb(0, 197, 142);
    }

    &::before {
      display: none;
    }
  }

  .slick-prev {
    left: 20px;
    &:hover {
      transform: translateX(-5px) scale(1.1);
    }
  }

  .slick-next {
    right: 20px;
    &:hover {
      transform: translateX(5px) scale(1.1);
    }
  }

  .custom-arrow {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 25px;
      height: 25px;
      color: #333;
      transition: color 0.3s ease;
    }

    &:hover svg {
      color: white;
    }
  }

  .slick-dots {
    bottom: 15px;

    li button:before {
      color: white;
      opacity: 0.8;
    }

    li.slick-active button:before {
      color: rgb(0, 197, 142);
      opacity: 1;
    }
  }
}

.carrusel-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

/* Estilos para la fila de información */
.fila-informacion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px; 
}

.informacion-titulo {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.informacion-descripcion {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: justify;
}

/* Estilos para la fila de opiniones */
.fila-opiniones {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  gap: 15px;
}

/* Encabezado de la sección de opiniones */
.opiniones-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.opiniones-titulo {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.btn-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #555;
  transition: color 0.3s ease;
}

.btn-icon:hover {
  color: #000;
}

/* Lista de opiniones */
.opiniones-lista {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Cada tarjeta de opinión */
.opinion-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Estilos para cada opinión */
.opinion-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.opinion-usuario {
  color: #333;
}

.opinion-fecha {
  font-size: 14px;
  color: #777;
}

.opinion-comentario {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.opiniones-no-disponibles {
  color: #888;
  font-size: 16px;
  text-align: center;
}

/* Estilos para la columna B */
.columnaB {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
  padding: 20px;
  background-color: rgb(147, 147, 147);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ubicación (mapa) */
.ubicacion-container {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.ubicacion-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Datos de contacto */
.datos-direccion,
.datos-telefono,
.datos-sitio-web,
.datos-horario {
  font-size: 16px;
  color: #555;
}

.datos-direccion p,
.datos-telefono p,
.datos-sitio-web p {
  margin: 5px 0;
}

.datos-telefono a,
.datos-sitio-web a {
  color: #007bff;
  text-decoration: none;
}

.datos-telefono a:hover,
.datos-sitio-web a:hover {
  text-decoration: underline;
}

/* Redes sociales */
.redes-sociales-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;

  a {
    color: #333;
    text-decoration: none;
    transition: transform 0.3s ease;
    font-size: 2rem;  
    
    &:hover {
      transform: scale(1.2);
      color: rgb(0, 197, 142);
    }
  }
}

/* Horario de atención */
.datos-horario h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.horario-table {
  width: 100%;
  border-collapse: collapse;
}

.horario-table th,
.horario-table td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.horario-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Contenedor principal */
.contenedor-principal {
  display: flex;
  gap: 20px; 
  padding: 20px;
  padding-top: 10rem;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
}

/* Columna A */
.columnaA {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px; 
  justify-content: flex-start; 
  height: auto;
}

/* Columna B */
.columnaB {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start; /* Asegura que el contenido esté alineado al principio */
  height: auto;
}

/* Otras clases existentes para las filas y elementos */
.fila {
  background-color: rgb(147, 147, 147);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Contenedor que agrupa el logo y el nombre */
.logo-nombre-contenedor {
  display: flex;
  align-items: center; /* Alinea verticalmente el logo y el nombre */
  gap: 20px; /* Espacio entre el logo y el nombre */
  width: 100%;
}

/* Logo de la empresa */
.logo-empresa {
  width: 150px;
  height: 150px;
}

.logo-empresa-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #ddd;
}

/* Nombre y slogan de la empresa */
.empresa-nombre-slogan {
  display: flex;
  flex-direction: column; /* El nombre va encima del slogan */
  justify-content: center; /* Centra verticalmente el nombre y el slogan */
  text-align: left; /* Alinea el texto a la izquierda */
}

.empresa-nombre {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.empresa-slogan {
  font-size: 16px;
  color: #777;
  margin-top: 5px;
}

/* Estilos base para las flechas */
.custom-arrow {
  z-index: 1;
  background: #333; /* Fondo oscuro */
  border-radius: 50%; /* Forma circular */
  width: 40px; /* Aumentar el tamaño de la flecha */
  height: 40px; /* Aumentar el tamaño de la flecha */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Tamaño del ícono de la flecha */
  color: #fff; /* Color de la flecha */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-arrow:hover {
  background-color: #555; /* Cambia el color de fondo al hacer hover */
}

/* Flecha izquierda */
.prev-arrow {
  left: -45px; /* Ajusta la posición a la izquierda fuera del carrusel */
}

/* Flecha derecha */
.next-arrow {
  right: -45px; /* Ajusta la posición a la derecha fuera del carrusel */
}

/* Asegurar que las flechas están posicionadas correctamente */
.slick-prev,
.slick-next {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 40px;
  height: 40px;
}

/* Estilos para versiones móviles */
@media (max-width: 768px) {
  /* Asegura que la columna A y B estén una debajo de la otra en pantallas pequeñas */
  .contenedor-principal {
    flex-direction: column;
    padding-top: 1rem;
    gap: 10px;
  }

  /* Ajustes para la columna A */
  .columnaA {
    width: 100%;
    padding: 10px;
  }

  /* Ajustes para la columna B */
  .columnaB {
    width: 100%; 
    padding: 10px;
  }

  /* Ajustar el logo y nombre de la empresa */
  .logo-nombre-contenedor {
    flex-direction: column; 
    align-items: center; 
  }

  .empresa-nombre-slogan {
    text-align: center;
  }

  .logo-empresa {
    width: 120px; 
    height: 120px;
  }

  /* Ajustes para el carrusel de imágenes */
  .carrusel-imagenes {
    width: 100%; 
  }

  /* Ajustes de las flechas del carrusel en móviles */
  .prev-arrow,
  .next-arrow {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .prev-arrow {
    left: -30px; 
  }

  .next-arrow {
    right: -30px; 
  }

  /* Ajustes de la fila de información */
  .fila-informacion {
    padding: 15px; 
  }

  /* Ajustes de la fila de opiniones */
  .fila-opiniones {
    padding: 15px; 
  }

  /* Ajustes para el mapa */
  .ubicacion-container {
    height: 250px;
  }

  /* Ajustes para los elementos de contacto */
  .datos-direccion,
  .datos-telefono,
  .datos-sitio-web,
  .datos-horario {
    font-size: 14px;
  }

  /* Ajustes para la tabla de horarios */
  .horario-table th,
  .horario-table td {
    padding: 6px 8px; 
  }
}

/* Estilos para pantallas más pequeñas (hasta 480px) */
@media (max-width: 480px) {
  /* Ajustar el logo en pantallas muy pequeñas */
  .logo-empresa {
    width: 100px;
    height: 100px;
  }

  /* Ajustes para el carrusel en pantallas muy pequeñas */
  .carrusel-imagenes {
    width: 100%; 
    height: 250px; 
  }

  .carrusel-img {
    height: 250px;
  }

  /* Ajustar las flechas en pantallas pequeñas */
  .prev-arrow,
  .next-arrow {
    width: 25px; 
    height: 25px;
    font-size: 14px;
  }

  .prev-arrow {
    left: -25px;
  }

  .next-arrow {
    right: -25px;
  }

  /* Ajustes de la fila de información y opiniones en pantallas pequeñas */
  .fila-informacion,
  .fila-opiniones {
    padding: 10px;
  }

  /* Ajustes para los elementos de contacto */
  .datos-direccion,
  .datos-telefono,
  .datos-sitio-web,
  .datos-horario {
    font-size: 12px; 
  }
}