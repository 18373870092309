/* --- Favoritos Section --- */
.favoritosSection {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 0px 10px 10px 0px;
}

.tituloFavoritos {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
}

.favoritosGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px;
  width: 100%;
}

.noResults {
  font-size: 1.2rem;
  color: #777;
  text-align: center;
  width: 100%;
}

/* --- Responsive adjustments for smaller screens --- */
@media (max-width: 1200px) {
  .favoritosGrid {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columnas en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .favoritosGrid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columnas en pantallas pequeñas */
  }
}

@media (max-width: 576px) {
  .favoritosGrid {
    grid-template-columns: 1fr; /* 1 columna en pantallas extra pequeñas */
  }
}
