/* Redes Sociales */
.campos-redes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.campo-red {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.campo-red label {
  margin-right: 0.5rem;
  width: 40px;
  text-align: center;
}

.campo-red input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.campo-red input:not(:placeholder-shown) {
  color: #000000;
}

.campo-red input::placeholder {
  color: #6b7280;
}

@media (max-width: 768px) {
  .campos-redes {
    grid-template-columns: 1fr;
  }

  .campo-red {
    margin-bottom: 1rem;
  }

  .campo-red label {
    width: 50px;
    font-size: 1.3rem;
  }

  .campo-red input {
    width: 100%;
  }
}
/* Visualización para CargaEmpresa*/
.campos-redes {
 display: block;
.custom-form-carga {
  margin: 0 auto; 
  padding: 0;
  display: grid; 
  grid-template-columns: repeat(2, 1fr); 
  gap: 1rem;

  .campo-red {
    display: flex;
    align-items: center;
    
    label {
      width: 40px;
      margin-right: 0.5rem;
      text-align: center;
      color: #ffffff;
      font-size: 1.1rem;
    }

    input {
      flex-grow: 1;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
      background-color: #2e3b4e;
      color: #ffffff;
      font-size: 1rem;
      width: 100%;
    }
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    border: none;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;

    .campo-red {
      margin-bottom: 1rem;
      
      label {
        width: 50px;
        font-size: 1.5rem;
      }
    }
  }
}
}