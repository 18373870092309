.background-calendario {
  background-color: whitesmoke;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.calendario {
  padding: 3rem;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 0.5rem;
}

.dia-calendario {
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.dia-calendario:hover {
  color: white;
  background-color: #241e4e;
}

select {
  appearance: none;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background-color: #f8f9fa;
  color: #495057;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 20%;
  text-align: center;
}

.texto-mes {
  font-size: x-large;
}

select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Agrega un estilo hover */
select:hover {
  border-color: #b3c6ff;
}

/* Estilo cuando el botón está desactivado */
select:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

/* Estilo para el ícono de flecha */
select:after {
  content: "\25BC"; /* Unicode para la flecha hacia abajo */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Evita que la flecha sea seleccionable */
}

/* Estilo para el contenedor del botón de los meses */
.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Mantén el resto de tus estilos para otros elementos */
.highlighted {
  background-color: rgb(0, 197, 142);
  color: black;
  font-weight: bold;
  border: 2px solid rgb(0, 197, 142);
}

.mes-calendario {
  text-decoration: underline red;
  font-weight: 700;
}

/* Estilos para los botones de carga y cancelar */
.btn-container .btn-cargar {
  background-color: #241e4e;
  font-weight: 600;
  border: 2px solid #000;
  padding: 16px 24px;
  width: 100%;
  text-align: center;
}

.btn-container .btn-cargar:hover {
  background-color: rgb(0, 197, 142);
  color: black;
}

.btn-container .btn-cancelar {
  background-color: rgb(117, 23, 23);
  font-weight: 600;
  border: 2px solid #000;
  padding: 16px 24px;
  width: 100%;
  text-align: center;
}

.btn-container .btn-cancelar:hover {
  background-color: #a71c00;
  color: white;
}

.popup-container {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 999;
  max-width: 80%;
  max-width: 400px;
  overflow-y: auto;
  margin-top: 4rem;
}

.popup-container h2 {
  margin-bottom: 10px;
  color: #333333;
  font-size: 1.5rem;
}

.popup-container textarea {
  width: calc(100% - 40px);
  height: 80px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  resize: none;
}

.popup-container input[type="date"],
.popup-container input[type="time"] {
  width: calc(100% - 20px);
}

.popup-container button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-container button.cancel {
  background-color: #f44336;
}

.popup-container button:hover {
  opacity: 0.8;
}

.popup select {
  margin-top: 10px; /* Espacio entre el textarea y el select */
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #ced4da;
  border-radius: 8px;
}

/* Estilos para los campos de inicio y fin de promoción */
.popup-container input[type="date"] {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

.popup-container input[type="date"]:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Estilos para los campos de entrada de hora y sus etiquetas */
.popup-container input[type="time"] {
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
}

.popup-container input[type="time"]:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.popup-container p {
  margin-top: 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .background-calendario {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .seleccion-mes select {
    width: 100%;
  }

  .calendario {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0;
    padding: 0;
  }

  .dia-calendario {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  /* PopUp Fecha */
  .popup-container {
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
  }

  .popup {
    padding: 1rem;
  }

  .popup h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .popup textarea {
    width: 100%; /* Ancho del textarea al 100% */
    max-width: 100%; /* Ancho máximo del textarea */
    margin-bottom: 1rem; /* Ajuste del margen inferior */
  }

  .popup select,
  .popup input[type="date"],
  .popup input[type="time"] {
    width: 100%; /* Ancho al 100% */
    max-width: 100%; /* Ancho máximo */
    margin-bottom: 1rem; /* Ajuste del margen inferior */
  }

  .btn-container {
    display: flex; /* Cambia a un diseño de flexbox */
    flex-direction: column; /* Dirección de columna */
    align-items: center; /* Centra los elementos horizontalmente */
  }

  .btn-container button {
    width: 100%; /* Ancho al 100% */
    max-width: 100%; /* Ancho máximo */
    margin-bottom: 0.5rem; /* Ajuste del margen inferior */
  }
}

@media screen and (max-width: 992px) {
  .background-calendario {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
