.background .custom-container {
  margin-top: 10rem;
}

.custom-title {
  font-size: 4rem;
  margin-top: 1rem;
  padding-top: 0;
  text-align: center;
  line-height: 1.1;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .background .custom-container {
    margin-top: 0rem;
  }

  .custom-title {
    font-size: 2rem;
    margin-top: 2rem;
    line-height: 1.2;
  }
}

.custom-container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 1rem;
}

@media (min-width: 992px) {
  .custom-container {
    text-align: left;
  }
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x, 1.5rem) * -0.5);
  margin-left: calc(var(--bs-gutter-x, 1.5rem) * -0.5);
  align-items: center;
  margin-bottom: 3rem;
}

.custom-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x, 1.5rem) * 0.5);
  padding-left: calc(var(--bs-gutter-x, 1.5rem) * 0.5);
}

@media (min-width: 992px) {
  .custom-row > * {
    padding-right: calc(var(--bs-gutter-x, 3rem) * 0.5);
    padding-left: calc(var(--bs-gutter-x, 3rem) * 0.5);
  }
}

.custom-col {
  width: 100%;
  padding-top: 2rem;
}

@media (min-width: 1400px) {
  .custom-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-container-mt {
  width: 100%;
  font-size: 20px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
}
/* Start Filter Km*/
.filter-row-km {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--bs-gutter-x, 0.75rem) * -0.5);
  margin-left: calc(var(--bs-gutter-x, 0.75rem) * -0.5);
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.filter-row-km > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x, 0.75rem) * 0.5);
  padding-left: calc(var(--bs-gutter-x, 0.75rem) * 0.5);
}
/* End Filter Km*/

/* Start CHECKBOX */
.filterType {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.filterTypeLabel,
.filterTypeInput {
  width: fit-content;
  color: white;
  align-items: center;
}

.filterTypeLabel {
  margin-left: 0.5rem;
  padding-right: 5rem;
  font-size: 1rem;
  color: rgb(0, 197, 142);
  text-align: center;
  justify-content: center;
}

.filterTypeInput {
  position: relative;
  width: 2rem;
  height: 2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgb(0, 197, 142);
  border-radius: 5rem;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.filterTypeInput::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.filterTypeInput:checked::before {
  opacity: 1;
}

.filterTypeInput:hover {
  background-color: rgba(0, 197, 142, 0.1);
  border-color: rgba(0, 197, 142, 0.5);
}

.form-label {
  font-weight: bold;
  color: white;
}
@media (max-width: 767px) {
  .filterType {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .filterTypeLabel,
  .filterTypeInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .filterType > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  .filterTypeLabel {
    margin-left: 5rem;
    margin-top: 0.5rem;
  }

  .custom-title {
    margin-top: 0.5rem;
  }

  .custom-container-mt {
    margin-top: 0.5rem;
  }
}

/* End Checkbox*/

.range {
  padding-top: 1rem;
}

#rangevalue {
  color: white;
  font-weight: bold;
}

.form-range {
  width: 40%;
  cursor: pointer;
  height: 10px;
  border: none;
  outline: none;
}

.form-range::-webkit-slider-thumb {
  background-color: rgb(0, 197, 142);
  width: 20px;
  height: 20px;
  border: none;
}

.form-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
}

.filter-row-cat {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.custom-select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-select:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select option {
  padding: 1rem;
  color: #333;
  background-color: #fff;
}

.custom-select option:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

@media (max-width: 767px) {
  .custom-select {
    width: 100%;
    max-width: 100%;
  }

  .custom-select option {
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  select.custom-select {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

.filtro-provincias {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.filtro-provincias select {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filtro-provincias select:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.filtro-provincias select option {
  padding: 1rem;
  color: #333;
  background-color: #fff;
}

.filtro-provincias select option:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.pagination-controls {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-control-button {
  width: 40px;
  background-color: #3dd697;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-control-button:hover {
  background-color: #32b882;
}

.grid-categories {
  display: flex;
  flex-wrap: wrap; 
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 1rem;
}

.grid-categories > * {
  flex: 1 1 calc(25% - 2rem); 
  box-sizing: border-box; 
}


@media (min-width: 576px) {
  .grid-categories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 992px) {
  .grid-categories {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
