/* src/styles/sass/_about.scss */
/*=== ABOUT ===*/
.about {
  margin: 2rem;
  background-color: #f8f9fa;
  padding: 80px 0;
  border-radius: 2rem;
}

.container-about {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 15px;
}

.row-about {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.col-lg-6-about {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.order-lg-1-about {
  order: 1;
}

.order-lg-2-about {
  order: 2;
}

.pt-4-about {
  padding-top: 1.5rem;
}

.pt-lg-0-about {
  padding-top: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.about h3 {
  font-size: 2.5rem;
  color: #333;
}

.about p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.about p + p {
  margin-top: 20px;
}

.about ul {
  list-style-type: none;
  padding-left: 0;
}

.about ul li {
  margin-bottom: 15px;
}

.about ul li i {
  color: rgb(0, 197, 142);
  margin-right: 10px;
  font-size: x-large;
}

@media (max-width: 768px) {
  .about {
    padding: 60px 0;
  }

  .container-about {
    padding: 0 10px;
  }

  .row-about {
    flex-direction: column;
    text-align: center;
  }

  .col-lg-6-about {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .about h3 {
    font-size: 2rem;
  }

  .about p {
    font-size: 1rem;
  }
}
