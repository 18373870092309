#footer {
  background: rgba(6, 6, 6, 0.643);
  color: #fff;
  font-size: 14px;
  position: relative;
}
#footer .footer-top {
  background: #151515;
  border-bottom: 1px solid #222222;
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}
#footer .footer-top .footer-info h3 span {
  color: rgb(0, 197, 142);
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 24px;
  display: inline-block;
  background: #292929;
  padding-top: 0.5rem;
  color: #fff;
  margin-right: 0.5rem;
  border-radius: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: rgb(0, 197, 142);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: rgb(0, 197, 142);
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: rgb(0, 197, 142);
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: rgb(0, 197, 142);
}

#footer .copyright {
  text-align: center;
  padding: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

* .form {
  width: 400px;
  padding: 30px;
  margin: auto;
  margin-block: 10rem;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  box-shadow: 3px 4px 8px #000;
}
* .form h4 {
  font-size: 22px;
  margin-bottom: 20px;
  color: rgb(20, 20, 20);
  text-align: center;
}
* .form select {
  width: 100%;
  background: #000000;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #717171;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: white;
}
* .form p {
  color: rgb(0, 0, 0);
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
}
* .form a {
  color: rgb(193, 204, 33);
  text-decoration: none;
}
* .form a:hover {
  color: rgb(190, 35, 35);
  text-decoration: underline;
}

.campo {
  width: 100%;
  background: #000000;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #717171;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: rgb(15, 14, 14);
}

.footer-newsletter .btn-news {
  width: 50%;
  background: rgb(0, 197, 142);
  padding: 12px;
  color: white;
  margin: 16px 0;
  font-size: 16px;
  border-radius: 1rem;
  border: 2px solid rgb(243, 243, 243);
  transition: 0.5s;
}

.footer-newsletter .btn-news:hover {
  background: #241e4e;
  color: white;
}

.modal {
  text-align: justify;
}

.modal-content {
  width: auto;
  height: auto;
}

.modal-footer {
  justify-content: center;
  align-items: center;
}

.modal-footer button {
  color: whitesmoke;
  background-color: black;
  border-radius: 1rem;
  width: 50%;
}

.vefrek-logo-footer {
  width: 10%;
}

@media (max-width: 992px) {
  .vefrek-logo-footer {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .vefrek-logo-footer {
    width: 40%;
  }
}

@media (min-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .footer-top .footer-links ul li {
    margin-left: 0rem;
  }

  .modal-content {
    width: 100%;
  }
}

.modal-title {
  color: black;
  font-size: xx-large;
}

.modal-title2 {
  font-size: x-large;
  margin-top: 1rem;
  text-align: start;
}

.modal-body p {
  color: gray;
}

.modal-title2,
h5,
p,
li {
  color: rgb(112, 110, 110);
}

@media (min-width: 768px) {
  .footer-links {
    text-align: center;
  }

  .modal-content {
    width: 100%;
  }
}

@media (min-width: 1366px) {
  .footer-links {
    text-align: center;
  }

  .modal-dialog {
    align-items: center;
    display: flex;
  }

  .modal-content {
    width: 150%;
  }
}
