.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background: linear-gradient(to right, rgb(0, 197, 142), #241e4e);
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.5s ease;
}

.dropdown-button:hover {
  background: linear-gradient(to right, #241e4e, rgb(0, 197, 142));
  color: rgba(246, 255, 0, 0.751);
}

.dropdown-content {
  display: block;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 1rem;
  margin-top: 10px;
}

.dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-item i {
  margin-right: 8px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.link-dropdown p {
  padding: 1rem;
  color: black;
}

.link-dropdown p:hover {
  background-color: rgba(128, 128, 128, 0.371);
  transition: 1s;
  border-radius: 2rem;
}

@media (max-width: 768px) {
  .dropdown-container {
    width: 100%;
  }

  .dropdown-content {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    margin-top: 5px;
  }

  .dropdown-button {
    font-size: 25px;
    width: 100%;
  }
}
