.values-section {
  margin-top: 2rem;
}

.container-values {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0 15px;
}

.row-values {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.col-lg-6-values {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
}

.image-values {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

.icon-box-values {
  display: flex;
  margin-top: 2rem;
}

.icon-box-values .icon {
  flex: 0 0 auto;
  margin-right: 15px;
}

.icon-box-values .icon i {
  font-size: 56px;
  color: rgb(0, 197, 142);
}

.icon-box-values h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.icon-box-values p {
  font-size: 15px;
  color: #848484;
}

@media (max-width: 768px) {
  .values-section {
    padding-top: 0;
  }

  .container-values {
    padding: 0;
  }

  .row-values {
    flex-direction: column;
    text-align: center;
  }

  .col-lg-6-values {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .image-values {
    min-height: 200px;
  }
}
