h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

body {
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #444444;
  text-align: left;
  position: relative;
}

a {
  color: #ffc451;
  text-decoration: none;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}

/*section {
  padding: 10rem 3rem 3rem 3rem;
  overflow: hidden;
  box-sizing: none;
}*/

@media (max-width: 768px) {
  section {
    padding: 2rem 3rem 3rem 3rem;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  margin: 1rem;
  bottom: 15px;
  background-color: rgb(61, 214, 151);
  z-index: 996;
  color: rgb(0, 197, 142);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
  z-index: 9999;
}

.back-to-top i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}

.back-to-top:hover {
  background: #151515;
}

.back-to-top:hover i {
  color: rgb(0, 197, 142);
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

/* Sweetalert 2 clases */

.swal2-container {
  z-index: 1200;
}
