.container-login {
  width: 100%;
  max-width: 1200px;
  padding-top: 8rem;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 992px) {
    text-align: left;
    padding: 0rem;
    margin: 0rem;
  }
}

.row-login {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  & > * {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 992px) {
    gap: 0rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.col-logo {
  width: 100%;
  margin-bottom: 3rem;

  @media (min-width: 992px) {
    flex: 0 0 48%;
    max-width: 48%;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    color: whitesmoke;

    img {
      width: 100%;
      max-width: 300px;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      color: #f0c674;
    }

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.login-card {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: rgb(239, 234, 234);
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 3rem;

  h2 {
    font-size: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.form-login {
  margin: 0.5rem 0;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-btn {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 1rem auto 1rem auto;
  background-color: #218838;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #f0c674;
    color: black;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;

  .btn-social-facebook,
  .btn-social-google {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.25rem;
    border-radius: 0.5rem;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    svg {
      margin-right: 10px;
    }

    &.btn-social-facebook {
      background-color: #3b5998;

      &:hover {
        background-color: #334f88;
      }
    }

    &.btn-social-google {
      background-color: #db4437;

      &:hover {
        background-color: #c13527;
      }
    }
  }
}

.login-links {
  font-size: 1rem;
  color: #525354;
  margin-top: 1rem;
  text-align: center;

  a,
  h4,
  h5 {
    margin-top: 1rem;
    font-weight: 500;
  }

  h4 a:hover {
    color: #218838;
    font-weight: 600;
  }

  h5 a:hover {
    color: #dc3545;
    font-weight: 600;
  }
}
