/* Imagenes empresa */
.carga-logo-section,
.carga-images-section {
  margin-bottom: 2rem;
}

.seclogo, .cuadro-edicion {
  justify-content: center;
  display: flex;
}

.change-img-logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.carga-seclogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.editar-empresa-logo, .icon-user {
  margin: 1rem;
    height: 150px;
    width: 150px;
    border-radius: 5rem;
}

.carga-seclogo img {
  max-width: 100px;
  max-height: 100px;
}

.secLogo {
  display: flex;
  justify-content: center;
}

.carga-seclogo button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.carga-seclogo button:hover {
  background-color: #0056b3;
}

.carga-empresa-logo {
  max-width: 100px;
  max-height: 100px;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}

.carga-btn-custom {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  background-color: #e27c2d;
  color: #ffffff;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carga-btn-custom:hover {
  background-color: #c56a23;
}

.carga-company-logo-input {
  display: none;
}

.carga-cuadro-edicion {
  border: 2px dashed #ccc;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carga-cuadro-edicion p {
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 0.5rem;
}

.carga-cuadro-edicion .carga-iconUser {
  font-size: 30px;
  color: #6b7280;
  margin-bottom: 0.5rem;
  height: 3rem;
  width: 3rem;
}

.carga-file-upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carga-file-upload-wrapper input[type="file"] {
  display: none;
}

.carga-file-upload-wrapper-label {
  background-color: #e27c2d;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.carga-file-upload-info {
  color: #6b7280;
  margin-top: 0.5rem;
  font-size: 15px;
  text-align: center;
}

.carga-file-upload-label input[type="file"] {
  display: none;
}

.carga-file-upload-wrapper span {
  color: #6b7280;
  margin-top: 0.5rem;
  text-align: center;
}

/* Visualizacion de imagenes */
.carga-imagenes-cont {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: nowrap;
}

.carga-imagen-cont {
  position: relative;
  width: calc(100% / 5 - 1rem);
}

.carga-imagen {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  object-fit: cover;
}

.carga-delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.carga-delete-icon:hover {
  opacity: 1;
}

.carga-imagen-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  color: #bbb;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .carga-cuadro-edicion {
    max-width: 200px;
    padding: 0.75rem;
  }

  .carga-imagenes-cont {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .carga-imagen-cont {
    width: calc(100% / 2 - 0.5rem);
  }

  .carga-imagen {
    height: 80px;
  }
}

/* Visualizacion de imagenes */
.editar-empresa-imagenes-cont {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: nowrap;
}

.editar-empresa-imagen-cont {
  position: relative;
  width: calc(100% / 5 - 1rem);
}

.editar-empresa-imagen {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  object-fit: cover;
}

.editar-empresa-delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.editar-empresa-delete-icon:hover {
  opacity: 1;
}

.imagen-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  color: #bbb;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .cuadro-edicion {
    max-width: 200px;
    padding: 0.75rem;
  }

  .editar-empresa-imagenes-cont {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .editar-empresa-imagen-cont {
    width: calc(100% / 2 - 0.5rem);
  }

  .editar-empresa-imagen {
    height: 80px;
  }
}
